import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { Event, EventInfo } from '@models/Event'

import { apiError, success } from '../../alerts/state'
import { fetchActiviteitenForEntity } from '../../entity/activiteiten/state'
import {
  fetchAssignedBijlagenForEntity,
  fetchBijlagenForEntity,
} from '../../entity/bijlagen/state'
import { request } from '../../gateways/api'
import { fetchProjectdossier } from '../../projectdossier/state'
import type { RootState } from '../../store'
import {
  fetchHoofdleidingProjecten,
  initialState as initialStateHoofdleiding,
  storeProject,
  updateMyList,
} from '../projecten/state'
import type { HoofdleidingProject } from '../projecten/types'

import type { OptionsDialogData, State } from './types'

const initialState: State = {
  data: {
    events: [],
  },
  dialog: {
    create: {
      open: false,
      data: {},
    },
    options: {
      open: false,
      data: {
        project: {},
      },
    },
  },
  project: undefined,
  onMyList: false,
  status: 'idle',
}

export const fetchHoofdleidingProject = createAsyncThunk<
  HoofdleidingProject,
  { id: string; usePendingState?: boolean }
>('fetch/hoofdleiding/project', async (payload, { dispatch }) => {
  try {
    const result = await request({
      url: `/rest/project/${payload.id}`,
      method: 'GET',
    })

    return result
  } catch (error: any) {
    dispatch(apiError('Fout bij ophalen van project', error))

    throw error
  }
})

export const createHoofdleidingProject = createAsyncThunk<
  HoofdleidingProject,
  Partial<HoofdleidingProject>
>('create/hoofdleiding/project', async (payload, { dispatch, getState }) => {
  const state = getState() as RootState
  const { data } = state.hoofdleidingProject.dialog.create

  try {
    const result = await request({
      url: '/rest/project',
      method: 'POST',
      data: { ...data, ...payload },
    })

    dispatch(success('Project succesvol aangemaakt'))
    dispatch(closeProjectDialog())

    if (data.linkedProjectdossier) {
      dispatch(fetchProjectdossier(data.linkedProjectdossier))
    }

    dispatch(fetchHoofdleidingProjecten(initialStateHoofdleiding.query))

    return result
  } catch (error: any) {
    dispatch(apiError('Fout bij aanmaken van project', error))

    throw error
  }
})

export const updateHoofdleidingEvent = createAsyncThunk(
  'save/hoofdleiding/event',
  async (data: EventInfo, { dispatch, rejectWithValue }) => {
    const { id } = data

    try {
      const result = await request<HoofdleidingProject>({
        url: `/rest/project/${id}/event`,
        method: 'POST',
        data,
      })

      dispatch(
        fetchAssignedBijlagenForEntity({
          type: 'project',
          id: result.aanvraagID,
        }),
      )

      dispatch(
        fetchBijlagenForEntity({
          type: 'project',
          id: result.aanvraagID,
        }),
      )

      dispatch(
        fetchActiviteitenForEntity({
          type: 'project',
          id: result.aanvraagID,
        }),
      )

      dispatch(fetchHoofdleidingProject({ id }))
      dispatch(storeProject(result))
      dispatch(closeProjectDialog())
      dispatch(success('Project succesvol gewijzigd'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij wijzigen van project', error))

      return rejectWithValue({ error })
    }
  },
)

export const saveEvent = createAsyncThunk(
  'save/hoofdleiding/event',
  async (data: EventInfo, { dispatch, rejectWithValue }) => {
    const { id } = data

    try {
      const result = await request<HoofdleidingProject>({
        url: `/rest/project/${id}/event`,
        method: 'POST',
        data,
      })

      dispatch(fetchHoofdleidingProject({ id, usePendingState: false }))
      dispatch(storeProject(result))

      dispatch(closeProjectOptionsDialog())
      dispatch(success('Wijziging succesvol opgeslagen'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij opslaan wijziging', error))

      return rejectWithValue({ error })
    }
  },
)

export const fetchLog = createAsyncThunk<Event[], any>(
  'fetch/hoofdleiding/events',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const result = await request<Event[]>({
        url: `/rest/project/${id}/event`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van project log', error))

      return rejectWithValue({ error })
    }
  },
)

const slice = createSlice({
  name: 'hoofdleiding/project',
  initialState,
  reducers: {
    openProjectDialog: (
      state,
      action: PayloadAction<{
        data: Partial<HoofdleidingProject>
        type: 'edit' | 'create'
      }>,
    ) => {
      state.dialog.create = {
        open: true,
        data: action.payload.data,
        type: action.payload.type,
      }
    },
    closeProjectDialog: (state) => {
      state.dialog.create = initialState.dialog.create
    },
    openProjectOptionsDialog: (
      state,
      action: PayloadAction<OptionsDialogData>,
    ) => {
      state.dialog.options.open = true
      state.dialog.options.data = action.payload
    },
    closeProjectOptionsDialog: (state) => {
      state.dialog.options.open = false
      state.dialog.options.data = initialState.dialog.options.data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHoofdleidingProject.pending, (state, action) => {
        state.status = 'loading'
        state.project =
          action.meta.arg.usePendingState === false
            ? state.project
            : initialState.project
      })
      .addCase(fetchHoofdleidingProject.fulfilled, (state, action) => {
        state.status = 'idle'
        state.project = action.payload
      })
      .addCase(fetchHoofdleidingProject.rejected, (state) => {
        state.status = 'error'
        state.project = initialState.project
      })
      .addCase(createHoofdleidingProject.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createHoofdleidingProject.fulfilled, (state, action) => {
        state.dialog.create = initialState.dialog.create
        state.project = action.payload
        state.status = 'idle'
      })
      .addCase(createHoofdleidingProject.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(fetchLog.pending, (state) => {
        state.data.events = initialState.data.events
      })
      .addCase(fetchLog.fulfilled, (state, action) => {
        state.data.events = action.payload
      })
      .addCase(fetchLog.rejected, (state) => {
        state.data.events = initialState.data.events
      })
      .addCase(updateMyList.fulfilled, (state, action) => {
        const { projecten } = action.payload

        if (state.project) {
          state.project.myList = projecten.includes(state.project.aanvraagID)
        }
      })
  },
})

export const getHoofdleidingProject = (state: RootState) => ({
  project: state.hoofdleidingProject.project,
  isError: state.hoofdleidingProject.status === 'error',
})

export const getHoofdleidingProjectDialogOpen = (state: RootState) =>
  state.hoofdleidingProject.dialog.create.open
export const getHoofdleidingProjectDialogData = (state: RootState) =>
  state.hoofdleidingProject.dialog.create.data
export const getHoofdleidingProjectDialogType = (state: RootState) =>
  state.hoofdleidingProject.dialog.create.type

export const getHoofdleidingProjectOptionsDialogOpen = (state: RootState) =>
  state.hoofdleidingProject.dialog.options.open
export const getHoofdleidingProjectOptionsDialogData = (state: RootState) =>
  state.hoofdleidingProject.dialog.options.data

export const getHoofdleidingProjectEvents = (state: RootState) =>
  state.hoofdleidingProject.data.events.filter(
    (event) => event.type !== 'product-annulering',
  )

export const getStatus = (state: RootState) => state.hoofdleidingProject.status

export const { reducer } = slice
export const {
  openProjectDialog,
  closeProjectDialog,
  openProjectOptionsDialog,
  closeProjectOptionsDialog,
} = slice.actions
